<template>
  <div style="background-color: #FAFAFA;padding-top: 25px;padding-bottom: 110px;min-height: 500px">
    <div class="center" style="overflow: hidden">
      <!--左边区域-->
      <div
        style="margin-right: 20px;width: 850px;min-height: 350px;float: left;background-color: white;border:1px solid rgba(220, 220, 220, .5);padding-bottom: 30px">
        <div style="width: 770px;margin: 0 auto">
          <div class="content-title">{{detailInfo&&detailInfo.title}}</div>
          <div class="content_time">创建时间：{{detailInfo&&detailInfo.createdTime}}</div>
          <div class="content_boxA" v-html="detailInfo&&detailInfo.content"/>
        </div>
        <div style="width: 770px;height:200px;margin: 0 auto; border-bottom: 1px solid rgba(220, 220, 220, .5);">
        </div>
        <div style="width: 770px;margin: 0 auto">
          <div style="overflow: hidden">
            <div style="padding: 30px 0;float: left">
              <span style="border-bottom: none;width: 50px;margin-right: 0">标签：</span> <span v-for="(item,index) of labelList" :key="index"
                                     style="width:auto!important;margin-left:10px;height: 30px;line-height: 30px;padding: 0 5px;border:1px solid rgba(220, 220, 220,1);border-radius: 10px">{{item}}</span>
            </div>
            <div style="float: right;padding: 44px 0;cursor: pointer" v-if="detailInfo.articleAttachments && detailInfo.articleAttachments[0].url"   @click="downloadFile(detailInfo.articleAttachments[0].url)"><img :src="download" alt="" style="vertical-align: text-top"><span style="text-decoration: none"
              >下载</span></div>

          </div>

          <div style="display: flex;justify-content: space-between">
            <div style="flex: 0.45;cursor: pointer" class="ellipse-1"
                 @click="getKnowledgeDetailById(preDetailInfo&&preDetailInfo.id)">
              上一篇：{{preDetailInfo&&preDetailInfo.title}}
            </div>
            <div style="flex: 0.45;cursor: pointer" class="ellipse-1"
                 @click="getKnowledgeDetailById(nextDetailInfo&&nextDetailInfo.id)">
              下一篇：{{nextDetailInfo&&nextDetailInfo.title}}
            </div>
          </div>
        </div>


      </div>
      <!--右边区域-->
      <patent-component/>
    </div>
  </div>
</template>

<script>

  import {
    getKnowledgeDetailById
  } from "../../plugins/api/encyclopediaKnowledgeApi";
  import newestPatentAndAchievement from "./newestPatentAndAchievement";
  import download from '@/assets/image/index/download.png'
  export default {
    name: "knowledgeDetail",
    data() {
      return {
        download:download,
        detailInfo: {},//文章详情信息
        preDetailInfo: {},//上一篇详情信息
        nextDetailInfo: {},//下一篇详情信息
        labelList: [],//标签数组
      }
    },
    components: {
      patentComponent: newestPatentAndAchievement,
    },
    mounted() {
      this.getKnowledgeDetailById(this.$route.query.id);
    },
    methods: {
      async downloadFile(url){
        let fileName=url;
        var x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.responseType = 'blob';
        x.onload=function(e) {
          //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
          var url = window.URL.createObjectURL(x.response)
          var a = document.createElement('a');
          a.href = url
          a.download = fileName;
          a.click()
        }
        x.send();
      },
      //根据ID获取文章详情
      async getKnowledgeDetailById(id) {
        if (!id) {
          return;
        }
        const params = {
          id: id
        }
        const json = await getKnowledgeDetailById(params);
        if (json && json.code === 0) {
          if (json.result.nowArticleVo) {
            scrollTo(0, 0)
            this.detailInfo = json.result.nowArticleVo;
            this.labelList = json.result.nowArticleVo.label.split(';');
          }
          if (json.result.preArticleVo) {
            this.preDetailInfo = json.result.preArticleVo
          }
          if (json.result.nextArticleVo) {
            this.nextDetailInfo = json.result.nextArticleVo
          }
        }
      },
    }
  }
</script>

<style scoped lang="css">

  /deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
    /*background-color: #fc7c40;*/
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    padding-right: 100px;
  }

  .content-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .content_time {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 30px;
  }

  .content_boxA >>> img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    height: auto !important;
  }

  .per_content div {
    margin-bottom: 5px;
  }

  .yi_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }


  span {
    width: 114px;
    margin: 0 auto;
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid rgba(220, 220, 220, .5);
    cursor: pointer;
  }


  .bq_select_span {
    border-bottom: none !important;
  }

  .div_classify {
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, .5);
    height: 50px;
  }

  span {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
  }


  .span_select:after {
    position: absolute;
    content: "";
    width: 40px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fc7c40;
  }

  .span_select {
    color: #fc7c40;
  }

  .bq_select {
    color: white;
    background-color: #fc7c40;
  }
</style>
